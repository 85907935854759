import request from '@/utils/request'


// 查询用户信息扩展列表
export function listProfile(query) {
  return request({
    url: '/user/user-profile/list',
    method: 'get',
    params: query
  })
}

// 查询用户信息扩展分页
export function pageProfile(query) {
  return request({
    url: '/user/user-profile/page',
    method: 'get',
    params: query
  })
}

// 查询用户信息扩展详细
export function getProfile(data) {
  return request({
    url: '/user/user-profile/detail',
    method: 'get',
    params: data
  })
}

// 新增用户信息扩展
export function addProfile(data) {
  return request({
    url: '/user/user-profile/add',
    method: 'post',
    data: data
  })
}

// 修改用户信息扩展
export function updateProfile(data) {
  return request({
    url: '/user/user-profile/edit',
    method: 'post',
    data: data
  })
}

// 删除用户信息扩展
export function delProfile(data) {
  return request({
    url: '/user/user-profile/delete',
    method: 'post',
    data: data
  })
}
